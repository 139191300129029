.filters {
    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }
    &__select {
        margin: 0 8px;
        p {
            margin-bottom: 4px;
            font-size: 14px;
            margin-left: -5px;
            color: black;
        }
    }

    &__spinner {
        position: relative;
        display: inline-block;
        width: 30px;
    }

    &__spinnerinner {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    &__chips {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-end;
        margin: 0 0 0 10px;
    }

    &__chip {
        margin: 0 5px 0 0;
    }

    &__subheader {
        margin: 0 0 12px 0;
    }

    &__container {
        margin: 30px 0 0 0;
    }

    &__tune {
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
    }

    
}
.page-me {
    width: 100%;

    .group {
        margin: 32px 0;
    }
    .bullet-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        color: white;
        border: 6px solid rgb(146, 41, 101);
        border-radius: 50%;
        margin: 0 8px 0 0;
    }

    .roletable {
        
        margin: 12px 0 0;
        display: block;
    }

    .roletable__title {
        width: 50%;
        padding: 4px 0;
    }

    .roletable__dates {
        color: rgb(92, 92, 92);
        width: 25%;
        padding: 4px 8px;
    }

    .roletable__row--active {
        .roletable__dates {
            color: black;
        }
    }

    .roletable__head {
        .roletable__dates {
            color: black;
            background: transparent;
        }
        .roletable__row {
            border-top: unset;
        }
    }

    .roletable__row {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        width: 100%;
        max-width: 600px;
        border-top: 1px solid grey;
    }

    .userinfo {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        &__name {
            font-size: 28px;
            line-height: 1.2;
            margin-bottom: 4px;
            font-weight: 400;
        } 
        button {
            flex-shrink: 0;
            background-color: #58123A;
            border-radius: 30px;
            padding: 13px 20px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 121%;
            text-align: center;
            letter-spacing: 0.21em;
            color: white;
            display: inline-block;
            &:hover {
                background-color: #380924;
            }
        }

    }

    .bundle {
        background-color: #F6F3F0;
        padding: 30px;
        margin-top: 50px;
        .row {
            display: flex;
            margin-bottom: 14px;
            &:last-of-type {
                margin-bottom: 0;
            }
            .left {
                width: 80%;
                padding-right: 20px;
            }
            .right {
                width: 20%;
            }
            p {
                margin: 0;
            }
            &.titles {
                border-bottom: 1px solid #0000001f;
                padding-bottom: 9px;
                p {
                    font-size: 20px;
                    line-height: 1.2;
                    margin-bottom: 4px;
                    font-weight: 400;
                }
            }
        }
    }
   
}
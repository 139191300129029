.chart-settings {
    display: flex;

    .chart-settings__select {
        margin-right: 5px;
    }
    .chart-settings__button {
        display: flex;
        button {
            background-color: rgba(132, 37, 88, 0.08);
            margin-right: 5px;
            padding: 6px 15px;
            &:hover {
                background-color: rgba(132, 37, 88, 0.18);
            }
            .button-icon {
                margin: 0;
            }
            .MuiButton-label {
                color: #842658;
                font-weight: 600;
                letter-spacing: 0.1em;
                font-size: 12px;
            }
        }
    }
}

.chart h3 {
    margin-top: 40px;
    font-size: 20px;
}

@import './components/header';
@import './components/filters';
@import './components/chart-settings';
@import './pages/frontpage';
@import './pages/me';
@import './pages/card-overview';
@import './pages/links';
@import './pages/reports';
@import './pages/download-logs-page';
* {
    font-family: 'Lato', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
    margin-top: 70px !important;
    margin: 0;
}

p {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
    letter-spacing: 0.02em;
}

.button-icon {
    margin: auto 8px auto 0;
    display: inline-flex;

    * {
        display: inline-block;
    }
}

.inputCat{
    width: 100%;
    max-width: none !important;
}

.paper{
        position: absolute;
        width: 400;
        background-color: white;
        border: '2px solid #000';


}

.section-graph {
    margin: 40px 0;
    border-bottom: 1px dashed #00000047;
    padding-bottom: 40px;

    &__header {
        margin-bottom: 12px;
        h4 {
            font-weight: 400;
        }
    }
}

h4{
    font-size:16px !important;
}

.section-graph .flex-container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: 200px;
    button {
        position: absolute;
        top: 4px;
        right: 0;
        color: black;
        font-weight: bold;
        background-color: rgba(132, 37, 88, 0.08);
        &:hover {
            background-color: rgba(132, 37, 88, 0.18);
        }
        .button-icon {
            margin: 0;
            color: #842658;
        }
    }
}

.modal.fade {
    opacity: 1;
}

.category-header-container {
    display: flex;
    justify-content: space-between;
}

.button-block {
    display: flex;
    justify-content: flex-end;
    align-items: self-start;
    button {
        margin-right: 5px;
    }
}

.rs-picker-menu {
    opacity: 1 !important;
}


.div-space {
    margin-top: 10px;
    margin-bottom: 10px;
}

.hide {
    display: none;
}

.inline {
    display: flex;
}

.center {
    display: flex;
    align-items: center;
    margin:0;
}


.table-header-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.small-text {
    font-size: 12px;
    margin:0;
    padding: 0;
}

.button:hover, .button:visited, .button:active, .button:focus {
    text-decoration: none;
    color: white;
    cursor: pointer;
}


.reports-overlay {
    position: absolute;
    top:0;
    width: 100%;
    height: 100%;
    background-color: #1D1D1D;
    opacity: 0.96;
    z-index: 999;
    color: black;

}

.overlay-download {
    position: absolute;
    top: 40%;
    left: 40%;
    background-color: #ffffff;
    padding: 50px;
    border-radius: 5px;
    z-index: 10000;
}

.header {
    background-color: #1D1D1D;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;

    &__inner {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        padding: 16px 16px;
    }

    &__logosection {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        position: relative;
        a {
            justify-content: center;
            align-content: center;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }

    &__logo {
        height: 14px;
        display: block;
    }



    &__nav {
    }
}


.desknav {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-end;

    &__list {
        margin: 0;
        display: inline-flex;
        list-style: none;
    }

    &__item {
        margin: 6px 12px;
    }

    &__link {
        color: #fff;
        text-decoration: none;;
    }
}


.headerfix {
    //margin-top: 67px;
    margin-top: 100px;
    display: block;
}





.admin-bar {
    .header {
        top: 32px;
    }
}

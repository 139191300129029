.reports-table {
  thead {
    background-color: #D5C6CE;
    font-weight: normal;

    th {
      font-weight: normal;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-left: 15px;
    }
  }

  td {
    //padding: 30px;
    //margin:30px;
  }

  tbody {
    td {
      padding-left: 15px;
    }
  }
}


.report-filter {
  margin-top: 20px;
  margin-bottom: 20px;
}


.frontpage_header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    background-color: #F6F3F0;

    .header-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 70px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
        @media screen and (max-width:1200px) {
            align-items: center;
        }
        @media screen and (max-width: 768px) {
            align-items: center;
        }
        .brand {
            display: flex;
            align-items: center;
            img {
                width: auto;
                height: 42px;
                padding-left: 30px;
                @media screen and (max-width: 768px) {
                    padding-left: 10px;
                }
            }
        }

        .menu-wrapper {
            .menu {
                display: flex;
                margin: 0;
                list-style: none;
                padding-left: 0;

                .menu-item {
                    position: relative;

                    &.active {
                        background-color:#380924  !important;
                        color:#FFFFFF  !important;
                        a {
                            color: white;
                        }

                    }

                    a {
                        padding: 0px 16px;
                        height: 70px;
                        font-style: normal;
                        font-weight: 300;
                        font-size: 14px;
                        line-height: 19px;
                        display: flex;
                        align-items: center;
                        text-align: right;
                        letter-spacing: 0.04em;
                        text-transform: uppercase;
                        color: black;
                        text-decoration: none;
                    }

                    .active  {
                        a{
                            color:white;
                        }

                    }


                    &.menu-item-has-children > a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        &:after {
                            content: '';
                            background-image: url('https://mediavision.se/wp-content/uploads/2019/11/Vector-2-1-e1573888429831.png');
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center;
                            width: 10px;
                            height: 10px;
                            margin: 2px 0 0 2px;
                        }
                    }

                    &.current-page-ancestor,
                    &.current-menu-item {
                        background-color:#380924;
                        color: #FFFFFF;
                        .nav-link {
                            color: #FFFFFF;
                        }
                    }

                    &:hover {
                        cursor: pointer;
                        background-color:#380924;
                        transition: all 0.2s ease;
                        > a {
                            color: #FFFFFF;
                            &:after {
                                background-image: url(https://mediavision.se/wp-content/uploads/2019/11/vector.png);
                            }
                        }

                        .sub-menu {
                            display: block;
                        }
                    }
                    .sub-menu {
                        display: none;
                        position: absolute;
                        flex-direction: column;
                        list-style: none;
                        padding-left: 0;
                        background-color:#F6F3F0;
                        color: black;
                        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
                        min-width: 100%;

                        .menu-item {
                            position: relative;

                            .nav-link {
                                padding: 23px 23px 23px 16px;
                                color: #000000;
                                height: auto !important;
                                width: auto !important;
                            }
                            &.current-page-ancestor,
                            &.current-menu-item {
                                background-color:#380924;
                                .nav-link {
                                    color: #FFFFFF;
                                }
                            }


                            &:hover {
                                cursor: pointer;
                                background-color:#380924;
                                transition: all 0.2s ease;
                                a {
                                    color: #FFFFFF;
                                }

                                .sub-menu {
                                    display: flex;
                                }
                            }
                        }

                        .sub-menu {
                            background-color: #F6F3F0;
                            @media screen and (min-width: 1200px) {
                                position: absolute;
                                top: 0;
                                left: 100%;
                                display: none;
                                padding-left: 0;
                                padding-right: 0;
                                width: max-content;
                            }
                            .menu-item {
                                .nav-link {
                                    color: #000000;
                                    width: min-content;
                                }
                                &:hover {
                                    cursor: pointer;
                                    background-color: #F6F3F0;
                                    transition: all 0.2s ease;
                                    color: #FFFFFF;
                                    .nav-link {
                                        color: #FFFFFF;
                                    }
                                }
                            }
                        }

                    }

                }

                &:not(.sub-menu) > .menu-item:nth-last-of-type(3):not(:first-of-type) {
                    .sub-menu{
                        .sub-menu {
                            @media screen and (min-width: 1200px) {
                                right: 100%;
                                left: unset;
                            }
                        }
                    }
                }

            }
        }
    }
}



.page_header {
    width: 100%;
	align-items: flex-start;
	padding: 55px 0;
	background-position: center !important;
	background-size: cover !important;
	background-repeat: no-repeat !important;
	height: 319px;
	display: flex;
	align-items: center;
	justify-content: center;
    position: relative;
    @media (max-width: 991.98px) {
		padding: 0;
		height: 234px;
	}
	>.container {
		display: flex;
		flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 1140px;
        padding: 15px;
		.page_title {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 56px;
            line-height: 67px;
            letter-spacing: 0.07em;
            text-transform: uppercase;
            color: #FFFFFF;
			@media (max-width: 991.98px) {
				word-break: break-word;
				word-wrap: break-word;
				font-size: 30px;
			}
		}
	}
}

.text-section,
.text-column {
    width: 100%;
    max-width: 1140px;
    padding: 15px;
    margin: 40px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media screen and (max-width: 768px) {
        padding: 0;
    }
}

.text-column__title {
    font-weight: 300;
    font-size: 48px;
    line-height: 130%;
    margin: 0;
    margin-bottom: 30px;
    width: 100%;
    @media screen and (max-width: 768px) {
        padding: 0 15px;
    }
}

.text-column__left {
    padding-right: 100px;
    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 0 15px;
    }
    .frontpage_welcome__body {
        margin-top: 0;
    }
}

.text-column .flex-container {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .box {
        width: calc(33.33% - 20px);
        margin-right: 20px;
        background-color: #F6F3F0;
        padding: 30px;
        &:last-of-type {
            margin-right: 0;
        }
        h3 {
            font-size: 20px;
            line-height: 1.2;
            margin-bottom: 10px;
            font-weight: 400;
        }
        a {
            background-color: #58123A;
            border-radius: 30px;
            padding: 13px 20px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 121%;
            text-align: center;
            letter-spacing: 0.21em;
            color: white;
            display: inline-block;
            margin-top: 30px;
            &:hover {
                background-color: #380924;
            }
        }
    }
}

.update-text {
    text-align: right;
    margin-top: 15px;
    width: 100%;
}

.section-link-with-background {
	display: flex;
	justify-content: center;
	background-color: #F6F3F0;
	align-items: flex-end;
	padding-top: 90px;
    padding-bottom: 90px;
	@media (max-width: 991.98px) {
		padding-top: 60px;
		padding-bottom: 60px;
	}
	.container {
        width: 100%;
        max-width: 1140px;
        padding: 15px;
	}
	.content {
		display: flex;
		flex-direction: row;
		.link-with-background_title {
			font-style: normal;
			font-weight: 300;
			font-size: 48px;
            line-height: 65px;
            margin: 0;
			p{
				margin-bottom: 0;
			}
			@media (max-width: 991.98px) {
                font-size: 28px;
                line-height: 1;
			}
		}
		.link-with-background_text {
			font-style: normal;
			font-weight: 300;
			font-size: 22px;
			line-height: 150%;
			letter-spacing: 0.02em;
			max-width: 858px;
			margin-top: 20px;
            margin-bottom: 30px;
			p{
				margin-bottom: 0;
			}
			@media (max-width: 991.98px) {
				margin-top: 10px;
				margin-bottom: 40px;
				font-size: 18px;
			}
		}
		.button{
            background-color: #58123A;
            border-radius: 30px;
            padding: 13px 20px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 121%;
            text-align: center;
            letter-spacing: 0.21em;
            color: white;
            display: inline-block;
            text-decoration: none;
            &:hover {
                background-color: #380924;
            }
		}
	}
}


.frontpage_welcome {
    margin: 20px 0 0;
    &__row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;

    }

    &__title {
        margin: 0 0 10px;
    }

    &__updated {
        margin: 0px 0 60px;
        font-weight: 300;
        font-size: 14px;
        display: none;
        @media screen and (max-width: 768px) {
            padding: 15px;
        }
    }
}

.frontpage_contact {
    margin: 30px 0 50px;
    h1 {
        margin: 0 0 10px;
    }
}

.frontpage_method {
    h2 {
        font-weight: 300;
        font-size: 26px;
        line-height: 35px;
        margin-bottom: 5px;
        margin-top: 10px;
    }

    &__box {
        margin: 5px 0 0;
        background: #e0e0e0;
        padding: 16px 16px;

        p {
            margin: 0;
        }
    }
}


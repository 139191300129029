.cards-overview {
    .main-inner {
        padding: 15px;
        margin: 100px auto 100px;
        max-width: 1140px;
        overflow: hidden;
        .cards-container {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 40px);
            margin-top: 30px;
            @media (max-width: 992px) {
                width: calc(100% + 15px);
            }
            @media (max-width: 600px) {
                width: 100%;
            }
            a {
                border-top: 3px solid #7d1147;
                box-shadow: 0px 5px 10px 2px #c1c1c1a3;
                width: calc(33.333% - 40px);
                margin-right: 40px;
                color: black;
                background: #f6f3f0;
                padding: 15px;
                margin-bottom: 40px;
                @media (max-width: 992px) {
                    width: calc(50% - 15px);
                    margin-right: 15px;
                }
                @media (max-width: 600px) {
                    width: 100%;
                    margin-right: 0;
                }
                &:hover {
                    text-decoration: none;
                }
                .date {
                    text-transform: uppercase;
                    font-weight: bold;
                    font-size: 14px;
                    letter-spacing: 2px;
                    color: #7d1147;
                    margin-top: 5px;
                }
            }
        }
    }
}

.single-card-inner {
    background: #f6f3f0;
    padding: 24px 0 100px;
    .main-inner {
        padding: 15px;
        margin: 0 auto;
        max-width: 1140px;
    }
    .content {
        background: white;
        padding: 40px;
        box-shadow: 0px 5px 10px 2px #c1c1c1a3;
        max-width: 960px;
        margin: 0 auto;
        @media (max-width: 600px) {
            padding: 15px;
        }
    }
    .date {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 2px;
        color: #7d1147;
        margin-top: 5px;
    }
    .video-container {
        position: relative;
        overflow: hidden;
        padding-top: 56.25%;
        margin: 20px auto;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}